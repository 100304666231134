<template>
  <section class="pb-2">
    <e-filters
      ref="filters"
      :title="$t('Filtros')"
      :searching="fetching"
      :search-text="$t('Pesquisar')"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <template #after_buttons>
        <e-button
          id="download"
          class="ml-1 btn-sm-block"
          variant="primary"
          icon="download"
          :busy="fetching"
          :text="$t('Baixar Relatório')"
          @click="downloadReport"
        />
        <!-- Button to download report (hidden by default) -->
        <a
          ref="downloadLink"
          style="display: none"
        />
      </template>
      <div>
        <b-row>
          <b-col md="6">
            <e-store-combo
              id="filters-store"
              v-model="filters.storeId"
              required
              @input="filters.checkingAccountId = null"
            />
          </b-col>

          <b-col md="6">
            <e-checking-account-combo
              v-model="filters.checkingAccountId"
              :type="['All']"
              :store-id="filters.storeId"
              required
              active-options
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <FormulateInput
              id="period"
              v-model="filters.period"
              type="date-range-picker"
              :label="$t('Período')"
              class="required"
              :time-picker="false"
              validation="requiredrange"
              :validation-messages="{
                requiredrange: $t('Informe um período'),
              }"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="checking-account-table"
        show-empty
        responsive
        striped
        class="bordered"
        :fields="fields"
        :items="checkingAccountEntries"
        :empty-text="getEmptyTableMessage($t('registro'), 'male')"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(value)="row">
          <span :class="row.item.operationType === 'Debit' ? 'text-danger' : 'text-success'">
            {{
              (row.item.operationType === 'Debit' ? row.item.value * -1 : row.item.value) | currency
            }}
          </span>
        </template>

        <template #cell(description)="row">
          <a
            v-if="row.item.financialWriteOffId || row.item.checkingAccountTransferId"
            @click="showCheckingAccountInfo(row.item)"
          >
            <span class="hyperlink">{{ row.item.description }}</span>
          </a>
          <span v-else>{{ row.item.description }}</span>
        </template>

        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(isInternal)="row">
          <e-status-badge
            :status="row.item.isInternal"
            :type="'yesNo'"
          />
        </template>

        <template #cell(isMain)="row">
          <e-status-badge
            :status="row.item.isMain"
            :type="'yesNo'"
          />
        </template>

        <template #custom-foot>
          <tr>
            <th
              class="text-right"
              colspan="6"
            >
              <div
                class="d-flex justify-content-end"
                style="gap: 10px"
              >
                <p class="m-0">
                  {{ $t('Saldo em') }} {{ filters.period.startDate | datetime }}:
                  {{ balances.initialBalance | currency }}
                </p>
                <p class="m-0">
                  |
                </p>
                <p class="m-0">
                  {{ $t('Saldo em') }} {{ filters.period.endDate | datetime }}:
                  {{ balances.finalBalance | currency }}
                </p>
                <p class="m-0">
                  |
                </p>
                <p class="m-0">
                  {{ $t('Saldo do período') }}: {{ balances.periodBalance | currency }}
                </p>
                <p class="m-0">
                  |
                </p>
                <p class="m-0">
                  {{ $t('Saldo atual') }}: {{ balances.currentBalance | currency }}
                </p>
              </div>
            </th>
          </tr>
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="checking-account-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <bank-deposit-sidebar
      ref="bankDepositSidebar"
      @save="getData"
    />

    <checking-account-transfer-sidebar
      ref="accountTransferSidebar"
      @save="getData"
    />

    <checking-account-info-sidebar
      id="cc_installment_sidebar"
      ref="checkingAccountInfoSidebar"
      @after-reverse="getData"
    />

    <fab
      v-if="fabActions"
      :main-tooltip="$t('Ações')"
      main-icon="keyboard_command_key"
      :fixed-tooltip="true"
      :actions="fabActions"
      @deposit="createBankDeposit"
      @transference="createAccountTransfer"
    />
  </section>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EStoreCombo,
  EButton,
  EStatusBadge,
  EFilters,
  ECheckingAccountCombo,
} from '@/views/components'
import { alerts, financialDomain } from '@/mixins'
import fab from '@/views/components/FAB.vue'
import CheckingAccountInfoSidebar from './components/CheckingAccountInfoSidebar.vue'
import BankDepositSidebar from '../checking-account-transfer/BankDepositSidebar.vue'
import CheckingAccountTransferSidebar from '../checking-account-transfer/CheckingAccountTransferSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    EStatusBadge,
    EStoreCombo,
    EFilters,
    EButton,
    ECheckingAccountCombo,
    CheckingAccountInfoSidebar,
    BankDepositSidebar,
    CheckingAccountTransferSidebar,
    fab,
  },

  mixins: [alerts, financialDomain],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/settings/financial/checkingAccountEntries', [
      'checkingAccountEntries',
      'reportData',
      'paging',
      'sorting',
      'filters',
      'summaryFields',
    ]),

    fabActions() {
      return [
        {
          name: 'deposit',
          icon: 'savings',
          tooltip: this.$t('Adicionar Depósito Conta Cofre'),
          hide: !this.$can('Create', 'BankDeposit'),
        },
        {
          name: 'transference',
          icon: 'account_balance',
          tooltip: this.$t('Adicionar Transferência Bancária'),
          hide: !this.$can('Create', 'CheckingAccountTransfer'),
        },
      ].filter(a => !a.hide)
    },

    fields() {
      return [
        {
          label: this.$t('Data'),
          key: 'statementDate',
          thStyle: { width: '150px' },
          tdClass: 'text-center',
          formatter: value => this.$options.filters.date(value),
        },
        {
          label: this.$t('Descrição'),
          key: 'description',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Forma de pagamento'),
          key: 'paymentMethod.name',
          tdClass: 'text-center',
        },
        {
          label: this.$t('Usuário'),
          key: 'createdBy',
          thStyle: { width: '180px' },
          tdClass: 'text-left',
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: val => this.$options.filters.currency(val),
        },
        {
          label: this.$t('Saldo'),
          key: 'balance',
          tdClass: 'text-right',
          thStyle: { width: '160px' },
          formatter: val => this.$options.filters.currency(val),
        },
      ]
    },

    balances() {
      return {
        currentBalance: this.summaryFields?.currentBalance?.value,
        periodBalance: this.summaryFields?.periodBalance?.value,
        initialBalance: this.summaryFields?.initialBalance?.value,
        finalBalance: this.summaryFields?.finalBalance?.value,
      }
    },
  },

  mounted() {
    // this.getData()
  },

  methods: {
    ...mapActions('pages/settings/financial/checkingAccountEntries', [
      'fetchCheckingAccountEntries',
      'generateReport',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),
    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchCheckingAccountEntries()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.getData()
    },
    filterData() {
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCheckingAccountInfo(item) {
      this.$refs.checkingAccountInfoSidebar.show(item)
    },

    createBankDeposit() {
      this.$refs.bankDepositSidebar.onShowSidebar()
    },
    createAccountTransfer() {
      this.$refs.accountTransferSidebar.onShowSidebar()
    },

    async downloadReport() {
      try {
        if (this.$refs.filters.isValid()) {
          this.fetching = true
          await this.generateReport()
          // Get the download link element from the template
          const { downloadLink } = this.$refs

          if (this.reportData) {
            // Set the URL and download attributes of the link
            downloadLink.href = this.reportData
            downloadLink.download = `Extrato_Loja_${this.filters.storeId}_IdConta_${
              this.filters.checkingAccountId
            }_${moment().format()}.xlsx`

            // Click the link to start the download
            downloadLink.click()
          } else {
            this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
          }
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>

<style></style>

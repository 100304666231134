var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "pb-2" },
    [
      _c(
        "e-filters",
        {
          ref: "filters",
          attrs: {
            title: _vm.$t("Filtros"),
            searching: _vm.fetching,
            "search-text": _vm.$t("Pesquisar"),
          },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _c("e-button", {
                    staticClass: "ml-1 btn-sm-block",
                    attrs: {
                      id: "download",
                      variant: "primary",
                      icon: "download",
                      busy: _vm.fetching,
                      text: _vm.$t("Baixar Relatório"),
                    },
                    on: { click: _vm.downloadReport },
                  }),
                  _c("a", {
                    ref: "downloadLink",
                    staticStyle: { display: "none" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("e-store-combo", {
                        attrs: { id: "filters-store", required: "" },
                        on: {
                          input: function ($event) {
                            _vm.filters.checkingAccountId = null
                          },
                        },
                        model: {
                          value: _vm.filters.storeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeId", $$v)
                          },
                          expression: "filters.storeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("e-checking-account-combo", {
                        attrs: {
                          type: ["All"],
                          "store-id": _vm.filters.storeId,
                          required: "",
                          "active-options": "",
                        },
                        model: {
                          value: _vm.filters.checkingAccountId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "checkingAccountId", $$v)
                          },
                          expression: "filters.checkingAccountId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "period",
                          type: "date-range-picker",
                          label: _vm.$t("Período"),
                          "time-picker": false,
                          validation: "requiredrange",
                          "validation-messages": {
                            requiredrange: _vm.$t("Informe um período"),
                          },
                        },
                        model: {
                          value: _vm.filters.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "checking-account-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              fields: _vm.fields,
              items: _vm.checkingAccountEntries,
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$t("registro"),
                "male"
              ),
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(value)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      {
                        class:
                          row.item.operationType === "Debit"
                            ? "text-danger"
                            : "text-success",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                row.item.operationType === "Debit"
                                  ? row.item.value * -1
                                  : row.item.value
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(description)",
                fn: function (row) {
                  return [
                    row.item.financialWriteOffId ||
                    row.item.checkingAccountTransferId
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.showCheckingAccountInfo(row.item)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "hyperlink" }, [
                              _vm._v(_vm._s(row.item.description)),
                            ]),
                          ]
                        )
                      : _c("span", [_vm._v(_vm._s(row.item.description))]),
                  ]
                },
              },
              {
                key: "cell(active)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.active },
                    }),
                  ]
                },
              },
              {
                key: "cell(isInternal)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.isInternal, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "cell(isMain)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.isMain, type: "yesNo" },
                    }),
                  ]
                },
              },
              {
                key: "custom-foot",
                fn: function () {
                  return [
                    _c("tr", [
                      _c(
                        "th",
                        { staticClass: "text-right", attrs: { colspan: "6" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-end",
                              staticStyle: { gap: "10px" },
                            },
                            [
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Saldo em")) +
                                    " " +
                                    _vm._s(
                                      _vm._f("datetime")(
                                        _vm.filters.period.startDate
                                      )
                                    ) +
                                    ": " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.balances.initialBalance
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("p", { staticClass: "m-0" }, [_vm._v(" | ")]),
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Saldo em")) +
                                    " " +
                                    _vm._s(
                                      _vm._f("datetime")(
                                        _vm.filters.period.endDate
                                      )
                                    ) +
                                    ": " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.balances.finalBalance
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("p", { staticClass: "m-0" }, [_vm._v(" | ")]),
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Saldo do período")) +
                                    ": " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.balances.periodBalance
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("p", { staticClass: "m-0" }, [_vm._v(" | ")]),
                              _c("p", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Saldo atual")) +
                                    ": " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.balances.currentBalance
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "checking-account-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("bank-deposit-sidebar", {
        ref: "bankDepositSidebar",
        on: { save: _vm.getData },
      }),
      _c("checking-account-transfer-sidebar", {
        ref: "accountTransferSidebar",
        on: { save: _vm.getData },
      }),
      _c("checking-account-info-sidebar", {
        ref: "checkingAccountInfoSidebar",
        attrs: { id: "cc_installment_sidebar" },
        on: { "after-reverse": _vm.getData },
      }),
      _vm.fabActions
        ? _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Ações"),
              "main-icon": "keyboard_command_key",
              "fixed-tooltip": true,
              actions: _vm.fabActions,
            },
            on: {
              deposit: _vm.createBankDeposit,
              transference: _vm.createAccountTransfer,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }